<template>
  <div class="ending-view-container">
    <div class="ending-view-ending">
      <div class="ending-view-frame33">
        <div class="ending-view-frame331">
          <span class="ending-view-text"><span>感谢您的互动</span></span>
          <span class="ending-view-text2">
            <span>您的愿望可能需要至多1分钟的时间才会出现在展览屏幕上。</span>
          </span>
          <div class="ending-view-frame332">
            <div class="ending-view-frame31">
              <span class="ending-view-text4" id="explaination0" @click="expand">
                <span>为什么没出现/提示被拦截？</span>
              </span>
              <img
                src="/playground_assets/svgdown11306-x9qj.svg"
                alt="SVGdown11306"
                class="ending-view-s-gdown1 hidden"
              />
              <img
                src="/playground_assets/svgdown21301-6e2o.svg"
                alt="SVGdown21301"
                class="ending-view-s-gdown2"
              />
            </div>
            <span class="ending-view-text6 hidden">
              AI算力有限，无法将全部的抽象概念全部提取。您可以简化您的愿望，多多提及自然意象，组织语言，重新参与互动。
            </span>
          </div>
        </div>
        <primary-button text="再写一个" :onClick="buttonClick" :showIcon= false></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/primary-button.vue'
export default {
  name: 'EndingView',
  components: {
    PrimaryButton,
  },
  metaInfo: {
  title: 'exported project',
  },
  methods:{
    expand(){
      document.querySelector('.ending-view-text6').classList.toggle('hidden')
      document.querySelector('.ending-view-s-gdown1').classList.toggle('hidden')
      document.querySelector('.ending-view-s-gdown2').classList.toggle('hidden')
    },
    buttonClick(){
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.hidden{
  display:none;
}
.ending-view-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.ending-view-ending {
  gap: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 60px 0;
  overflow: hidden;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 252, 1);
}
.ending-view-frame33 {
  width: auto;
  height: 701px;
  display: flex;
  padding: 60px;
  max-width: 600px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
.ending-view-frame331 {
  gap: 23px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ending-view-text {
  color: rgba(68, 68, 68, 1);
  width: 296px;
  height: auto;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ending-view-text2 {
  color: rgba(68, 68, 68, 1);
  width: 296px;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ending-view-frame332 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.ending-view-frame31 {
  display: flex;
  align-items: center;
  border-color: rgba(200, 200, 200, 1);
  border-style: solid;
  border-width: Symbol(figma.mixed)px;
}
.ending-view-text4 {
  color: rgba(200, 200, 200, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ending-view-s-gdown1 {
  width: 15px;
  height: 15px;
}
.ending-view-s-gdown2 {
  width: 15px;
  height: 15px;
}
.ending-view-text6 {
  color: rgb(200, 200, 200);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ending-view-btn {
  gap: 11px;
  width: 186px;
  display: flex;
  padding: 17px 25px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(51, 129, 94, 1);
}
.ending-view-text7 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 479px) {
  .ending-view-frame33 {
    width: auto;
    max-width: 600px;
  }
}
</style>
