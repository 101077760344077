<template>
    <button :disabled="disabled" :onclick="onClick" class="instruction-doanddonts-view-btn">
    <span class="instruction-doanddonts-view-text22">
    <!-- <span></span> -->
    <span>{{text}}</span>
    </span>
    <img v-if="showIcon"
    src="/playground_assets/svgcheck1i130-bz2.svg"
    alt="SVGcheck1I130"
    class="instruction-doanddonts-view-s-gcheck1"
    
    />
</button>
</template>

<script>
export default {
    name: 'PrimaryButton',
    // data() {
        
    // },
    props: {
        text: {
            type: String,
            default: '知道了'
        },
        showIcon: {
            type: Boolean,
            default: true
        },
    

        onClick: {
            type: Function,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    metaInfo: {
        title: '望梦之洲',
    },
    
}
</script>

<style>
.instruction-doanddonts-view-btn {
  gap: 11px;
  width: 186px;
  display: flex;
  padding: 17px 25px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(51, 129, 94, 1);
}
.instruction-doanddonts-view-btn:hover {
  background-color: #23704e;
  transition: 0.1s ease-in-out;
}
.instruction-doanddonts-view-btn:disabled {
  background-color: rgba(51, 129, 94, 0.28);
  transition: 0.1s ease-in-out;

}
.instruction-doanddonts-view-text22 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-s-gcheck1 {
  width: 24px;
  height: 24px;
}
</style>