<template>
  <div class="instruction-doanddonts-view-container">
    <div class="instruction-doanddonts-view-instructiondoanddonts">
      <div class="instruction-doanddonts-view-frame33">
        <div class="instruction-doanddonts-view-frame331">
          <span class="instruction-doanddonts-view-text">
            <span>互动指导</span>
          </span>
          <div class="instruction-doanddonts-view-frame38">
            <div class="instruction-doanddonts-view-frame37">
              <div class="instruction-doanddonts-view-frame31">
                <img
                  src="/playground_assets/svggoodtwo1301-henp.svg"
                  alt="SVGgoodtwo1301"
                  class="instruction-doanddonts-view-s-ggoodtwo"
                />
                <span class="instruction-doanddonts-view-text02">
                  <span>这样互动效果最好</span>
                </span>
              </div>
              <div class="instruction-doanddonts-view-frame332">
                <span class="instruction-doanddonts-view-text04">
                  <span>我想在森林里漫步</span>
                </span>
                <span class="instruction-doanddonts-view-text06">
                  <span>跟自然息息相关</span>
                </span>
              </div>
              <div class="instruction-doanddonts-view-frame34">
                <span class="instruction-doanddonts-view-text08">
                  <span>life on the tree</span>
                </span>
                <span class="instruction-doanddonts-view-text10">
                  <span>可以使用英语</span>
                </span>
              </div>
            </div>
            <div class="instruction-doanddonts-view-frame36">
              <div class="instruction-doanddonts-view-frame311">
                <img
                  src="/playground_assets/svgbadtwo1301-vvsr.svg"
                  alt="SVGbadtwo1301"
                  class="instruction-doanddonts-view-s-gbadtwo"
                />
                <span class="instruction-doanddonts-view-text12">
                  <span>不要这样互动</span>
                </span>
              </div>
              <div class="instruction-doanddonts-view-frame333">
                <span class="instruction-doanddonts-view-text14">
                  <span>
                    绿水青山就是金山银山
                    <span v-html="rawu8nx"></span>
                  </span>
                </span>
                <span class="instruction-doanddonts-view-text16">
                  <span>不要背诵政宣标语</span>
                </span>
              </div>
              <div class="instruction-doanddonts-view-frame341">
                <span class="instruction-doanddonts-view-text18">
                  <span>我想吃麦当劳</span>
                </span>
                <span class="instruction-doanddonts-view-text20">
                  <span>不能与自然无关</span>
                </span>
              </div>
              <div class="instruction-doanddonts-view-frame35">
                <span class="instruction-doanddonts-view-text22">
                  <span>家人们谁懂啊</span>
                </span>
                <span class="instruction-doanddonts-view-text24">
                  <span>使用标准语言</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <primary-button :onClick="onPrimaryButtonClick"></primary-button>     

      </div>
    </div>
  </div>
</template>
<script>
import PrimaryButton from './primary-button.vue'
import router from '@/router'
export default {
  name: 'InstructionDoanddonts',
  
    data() {
    return {
      rawl86i: ' ',
    }
  },
  metaInfo: {
    title: 'exported project',
  },
  components: {
    PrimaryButton,
  },
  methods: {
    onPrimaryButtonClick() {
      router.push('/question')
    },
  },
}
</script>

<style scoped>
.instruction-doanddonts-view-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.instruction-doanddonts-view-instructiondoanddonts {
  gap: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 60px 0;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 252, 1);
}
.instruction-doanddonts-view-frame33 {
  gap: 191px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.instruction-doanddonts-view-frame331 {
  gap: 23px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.instruction-doanddonts-view-text {
  color: rgba(68, 68, 68, 1);
  width: 296px;
  height: auto;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame38 {
  gap: 39px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.instruction-doanddonts-view-frame37 {
  gap: 11px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.instruction-doanddonts-view-frame31 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.instruction-doanddonts-view-s-ggoodtwo {
  width: 31px;
  height: 31px;
}
.instruction-doanddonts-view-text02 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 15px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame332 {
  gap: 66px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.instruction-doanddonts-view-text04 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-text06 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 15px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame34 {
  gap: 66px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.instruction-doanddonts-view-text08 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-text10 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 15px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame36 {
  gap: 11px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.instruction-doanddonts-view-frame311 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.instruction-doanddonts-view-s-gbadtwo {
  width: 31px;
  height: 31px;
}
.instruction-doanddonts-view-text12 {
  color: rgba(228, 131, 69, 1);
  height: auto;
  font-size: 15px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame333 {
  gap: 34px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.instruction-doanddonts-view-text14 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-text16 {
  color: rgba(228, 131, 69, 1);
  height: auto;
  font-size: 15px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame341 {
  gap: 98px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.instruction-doanddonts-view-text18 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-text20 {
  color: rgba(228, 131, 69, 1);
  height: auto;
  font-size: 15px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-frame35 {
  gap: 98px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.instruction-doanddonts-view-text22 {
  color: rgba(68, 68, 68, 1);
  height: auto;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-text24 {
  color: rgba(228, 131, 69, 1);
  height: auto;
  font-size: 15px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-btn {
  gap: 11px;
  width: 186px;
  display: flex;
  padding: 17px 25px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(51, 129, 94, 1);
}
.instruction-doanddonts-view-text26 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-doanddonts-view-s-gcheck1 {
  width: 24px;
  height: 24px;
}

</style>