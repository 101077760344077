<template>
  <div class="instruction-general-view-container">
    <div class="instruction-general-view-instructiongeneral">
      <div class="instruction-general-view-frame33">
        <div class="instruction-general-view-frame331">
          <span class="instruction-general-view-text"><span>互动指导</span></span>
          <span class="instruction-general-view-text02">
            <span class="instruction-general-view-text03">
              描绘自己的愿望，多提及
            </span>
            <span class="green_emphisis">自然风物</span>
          </span>
        </div>
        <div class="instruction-general-view-frame28">
          <div class="instruction-general-view-group25">
            <img
              src="/playground_assets/image2211233-wwi-200h.png"
              alt="IMAGE2211233"
              class="instruction-general-view-image221"
            />
            <div class="instruction-general-view-frame26">
              <span class="instruction-general-view-text05">雪</span>
            </div>
          </div>
          <div class="instruction-general-view-group26 hidden">
            <img
              src="/playground_assets/image3211232-6qbk-200w.png"
              alt="IMAGE3211232"
              class="instruction-general-view-image321"
            />
            <div class="instruction-general-view-frame261">
              <span class="instruction-general-view-text06">树</span>
            </div>
          </div>
          <div class="instruction-general-view-group27 hidden">
            <div class="instruction-general-view-frame27">
              <span class="instruction-general-view-text07">田</span>
            </div>
            <img
              src="/playground_assets/image3221233-45o-200h.png"
              alt="IMAGE3221233"
              class="instruction-general-view-image322"
            />
          </div>
          <div class="instruction-general-view-group28 hidden">
            <img
              src="/playground_assets/image211232-8wdo-200w.png"
              alt="IMAGE211232"
              class="instruction-general-view-image21"
            />
            <div class="instruction-general-view-frame25">
              <span class="instruction-general-view-text08">花</span>
            </div>
          </div>
        </div>
        <primary-button text="知道了" :onClick="buttonClick"></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/primary-button.vue'
export default {
  name: 'InstructionGeneral',
  components: {
    PrimaryButton,
  },
  props:{
    buttonClick:{
      type:Function,
      default:()=>{}
    }
  },
  // data() {
  //   return {
  //     rawar13: ' ',
  //   }
  // },
  metaInfo: {
    title: '望梦之洲',
  },
  mounted() {
    
   //get all child under .instruction-general-view-frame28 and show them in rotation
    let parentNode = document.querySelector('.instruction-general-view-frame28');
    let children = parentNode.childNodes;
    let i = 1;
    setInterval(() => {
      // console.log(i)
      if(i == 0){
        children[children.length - 1].classList.add('hidden')
        children[children.length - 1].classList.remove('fade-in');
        children[i].classList.remove('hidden');
        children[i].classList.add('fade-in');
        i++;
        return;
      }else{
        children[i - 1].classList.add('hidden');
        children[i - 1].classList.remove('fade-in');
        children[i].classList.remove('hidden');
        children[i].classList.add('fade-in');
        if(i  == children.length - 1){
          i = 0
      }else{
        i++;
      }
      }
        

    }, 3000);

    




  },
}
</script>

<style scoped>
.hidden{
  display:none !important;
}
.fade-in{
  animation: appear 1s;
}
@keyframes appear{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


.instruction-general-view-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.instruction-general-view-instructiongeneral {
  gap: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 60px 0;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 252, 1);
}
.instruction-general-view-frame33 {
  gap: 191px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.instruction-general-view-frame331 {
  gap: 23px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.instruction-general-view-text {
  color: rgba(68, 68, 68, 1);
  width: 296px;
  height: auto;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-general-view-text02 {
  color: rgba(68, 68, 68, 1);
  width: 296px;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-general-view-text03 {
  color: rgba(68, 68, 68, 1);
  font-weight: 400;
}
.instruction-general-view-frame28 {
  gap: 31px;
  display: flex;
  align-items: flex-start;
}
.instruction-general-view-group27 {
  width: 65px;
  height: 109px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.instruction-general-view-image221 {
  top: 0px;
  left: 0px;
  width: 65px;
  height: 56px;
  position: absolute;
}
.instruction-general-view-frame26 {
  gap: 14px;
  top: 74px;
  left: 20px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.instruction-general-view-text05 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 219.79999542236328%;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-general-view-group25 {
  width: 35px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.instruction-general-view-image321 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 66px;
  position: absolute;
}
.instruction-general-view-frame261 {
  gap: 14px;
  top: 80px;
  left: 10px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.instruction-general-view-text06 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 219.79999542236328%;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-general-view-group28 {
  width: 66px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.instruction-general-view-frame27 {
  gap: 14px;
  top: 65px;
  left: 25px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.instruction-general-view-text07 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 219.79999542236328%;
  font-stretch: normal;
  text-decoration: none;
}
.instruction-general-view-image322 {
  top: 0px;
  left: 0px;
  width: 66px;
  height: 37px;
  position: absolute;
}
.instruction-general-view-group26 {
  width: 59px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.instruction-general-view-image21 {
  top: 0px;
  left: 0px;
  width: 59px;
  height: 66px;
  position: absolute;
}
.instruction-general-view-frame25 {
  gap: 14px;
  top: 80px;
  left: 22px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.instruction-general-view-text08 {
  color: rgba(51, 129, 94, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 219.79999542236328%;
  font-stretch: normal;
  text-decoration: none;
}

.green_emphisis{
  font-family: 'Noto Sans SC';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 219.8%;
/* or 44px */


color: #33815E;
}
</style>
