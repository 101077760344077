<template>
  <div class="landing-view-container">
    <div class="landing-view-landing">
      <div class="landing-view-content-container">
        <div class="landing-view-frame32">
          <img
            src="/playground_assets/logo1307-esip-200h.png"
            alt="Logo1307"
            class="landing-view-logo"
          />
        </div>
        
      </div>
      <span class="loading-text">
          请稍等...
        </span>
    </div>
    
  </div>
</template>

<script>
import router from '../router'

export default {
  name: 'LandingView',
  
  metaInfo: {
    title: '望梦之洲 TerraIsle',
  },
    methods: {
      getCookie(name) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
          }
        }
        return null;
      },
        setCookie(name, value) {
        document.cookie = `${name}=${value}; path=/`;
      },
      showBlocked() {
        document.querySelector('.loading-text').innerHTML = "";
        this.$root.$toast.open(
              {
                message: '发生错误，请重试',
                type: 'error',
                position: 'bottom',
                duration: 0,
                dismissible: false,
              });
      },
      showEnded() {
        document.querySelector('.loading-text').innerHTML = "";
        this.$root.$toast.open(
              {
                message: '活动已结束',
                type: 'info',
                position: 'bottom',
                duration: 0,
                dismissible: false,
              });
      },
      goToNextPage(){
        //set fadeout class to the landing page
        //timeout
 
        //check if instructionSeen cookie is true, if it is, go to questionview
        document.querySelector('.loading-text').text = "已连接"
        const instructionSeen = this.getCookie('instructionSeen');
        

        
        setTimeout(() => {
          console.log(instructionSeen);
          if(instructionSeen === 'true'){

          router.replace('/question');
          return;
        }else{
          router.replace('/instruction')

        }
          //go to the next page

        }, 750)
        // router.replace('/examples')
      }
    },
  mounted() {
    //if url is localhost, then allow user to click title to go to the next page
    if(window.location.href.indexOf('localhost') > -1){
      this.goToNextPage();
      return;
    }

    var requestOptions = {
    method: 'GET',
    redirect: 'follow'
    //no-cors

    };
    var result_json;
    fetch("https://api.capsuleer.me/terraisle/internal/handshake", requestOptions)
      .then(response => response.text())
      .then(result => {
        // console.log(result)
        result_json = JSON.parse(result);
        // console.log(result_json)
        if(result_json.code == 2)
        {
          this.showBlocked();
          //if url is localhost, then allow user to click title to go to the next page
          if(window.location.href.indexOf('localhost') > -1){
            document.querySelector('.title').addEventListener('click', () => {
              this.goToNextPage();
            })
          }
          
          return;
        }else if(result_json.code ==0){
          this.showEnded();
          return;
        }
        // Function to set a cookie with a given name and value
        function setCookie(name, value) {
          document.cookie = `${name}=${value}; path=/`;
        }
        function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
          }
        }
        return null;
      }
        // Read the unique_id cookie
      const uniqueId = getCookie('unique_id');
      // Use the value of the unique_id cookie
      if (uniqueId == null) {
        setCookie('unique_id', result_json.sessionToken);
      }
        setTimeout(() => {
          this.goToNextPage();
        }, 2000);
      })
      .catch(error => {
        console.log('error', error)
        
        //wait for 2 seconds and try again
        setTimeout(() => {this.showBlocked()}, 2000);
        return;
      
      });
    }
}
</script>

<style scoped>
.loading-text{
  color: rgba(255, 255, 255, 0.5);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
}
.landing-view-container {
  width: 100%;
  display: flex;
  overflow: auto;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landing-view-landing {
  gap: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  /* padding: 60px 0; */
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: rgba(255, 255, 252, 1);
  background-image: url("../assets/background_optimized2.png");
}
.landing-view-content-container {
  width: 174px;
  height: 80px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.landing-view-frame32 {
  top: 0px;
  left: 0px;
  height: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
.landing-view-logo {
  width: 174px;
  height: 85px;
}


</style>
