<template>
  <instruction-doanddonts  class="hidden" id="instruction-doanddonts"></instruction-doanddonts>
  <instruction-general :buttonClick="ShowDoAndDonts" id="instruction-general"></instruction-general>
</template>

<script>
import instructionGeneral from '@/components/instruction-general.vue';
import instructionDoanddonts from '@/components/instruction-doanddonts.vue';
export default {
  components: { instructionDoanddonts,instructionGeneral },
  name: 'InstructionView',
  methods:{
    ShowDoAndDonts(){
      document.querySelector('#instruction-general').classList.add('hidden');
      document.querySelector('#instruction-doanddonts').classList.remove('hidden');
    },getCookie(name, value) {
          document.cookie = `${name}=${value}; path=/`;
        },
        setCookie(name, value) {
        document.cookie = `${name}=${value}; path=/`;
      },
  },
  metaInfo: {
    title: '望梦之洲',
  },
  data(){
    return{
      currentComponent: 'instruction-general',
    }
  },
  mounted() {
 
  //set a cookie to record if the user has seen the instruction
  this.setCookie("instructionSeen","true")

    
    
  },
}
</script>

<style scoped>
 .hidden{
    display:none;
 }
.fade-in{
  animation: appear 1s;
}
@keyframes appear{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
</style>
